const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "User name",
          class: "",
          value: "",
          required: true,
        },
      },
      {
        name: "Phone",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "User phone",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Address",
    options: [
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "User area",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "city",
          placeholder: "User city",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "state",
          placeholder: "User state",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Country",
        type: "input",
        form: {
          type: "text",
          name: "country",
          placeholder: "User country",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
];

export default forms;
