import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_product_brand = async (brand) => {
  try {
    const data = {
      name: brand?.name,
      status: brand?.status,
      branch: brand?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-product-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_product_brand = async (brand) => {
  try {
    const data = {
      id: brand?.id,
      name: brand?.name,
      status: brand?.status,
      branch: brand?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-product-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const delete_product_brand = async (brand) => {
  try {
    const data = {
      id: brand?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/delete-product-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_brand = async (brand) => {
  try {
    const data = {
      id: brand?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-product-brand`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_product_brands = async (brand) => {
  try {
    const data = {
      search: brand?.search,
      status: brand?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-brands`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_brand_log = async (brand) => {
  try {
    const data = {
      search: brand?.search,
      sort: brand?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-brand-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_product_brand_logs = async (brand) => {
  try {
    const data = {
      search: brand?.search,
      sort: brand?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-brand-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_product_brand,
  update_product_brand,
  delete_product_brand,
  get_product_brand,
  get_all_product_brands,
  get_product_brand_log,
  get_all_product_brand_logs,
};
