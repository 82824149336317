const headers = {
  name: "Inventory batch",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      value: null,
      options: [
        { value: "1", label: "Name (A-Z)" },
        { value: "2", label: "Name (Z-A)" },
        { value: "3", label: "Expiry (ASC)" },
        { value: "4", label: "Expiry (DES)" },
        { value: "5", label: "Purchase (Low)" },
        { value: "6", label: "Purchase (High)" },
        { value: "7", label: "Sale (Low)" },
        { value: "8", label: "Sale (High)" },
      ],
    },
    {
      type: "add",
      path: "/app/inventory/add",
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Batch",
        path: "",
      },
      // {
      //   name: "Transfer",
      //   path: "/app/inventory/transfer",
      // },
      // {
      //   name: "Received",
      //   path: "/app/inventory/received",
      // },
      // {
      //   name: "Request",
      //   path: "/app/inventory/stock",
      // },
    ],
    total: [
      { value: "1", label: "Total" },
      { value: "1", label: "Active" },
      { value: "1", label: "Inactive" },
      { value: "1", label: "Paid" },
      { value: "1", label: "Partial" },
      { value: "1", label: "Unpaid" },
    ],
  },
};

export default headers;
