import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import { get_all_inventories } from "../../../../Controllers/Inventory/Batch";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Inventory/Batch/tables";
import headers from "../../../../Data/Tables/Inventory/Batch/headers";
import Loadercomponent from "../../../../Componets/Loader";
import ValueTag from "../../../../Componets/Valuetag";
import moment from "moment";

const ProductsTable = () => {
  const [loader, setLoader] = useState(false);

  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        unit: tabledata?.unit,
        category: tabledata?.category,
        brand: tabledata?.brand,
        type: tabledata?.type,
        status: tabledata?.status,
      };

      const products = await get_all_inventories(data);
      if (products?.status) {
        let data = [];

        products?.data?.map((value, index) => {
          data?.push({
            Number: `BATCH-${value?.number}`,
            Product: value?.product?.name,
            Purchase: value?.purchase_price,
            Sale: value?.sale_price,
            Stock: value?.stock,
            Tax: value?.tax,
            Expiry: value?.expiry_date
              ? moment?.(value?.expiry_date)?.format("MMMM Do YYYY")
              : "",
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/inventory/edit/${value?._id}`}
                id={value?._id}
                api="delete-inventory"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(products?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default ProductsTable;
