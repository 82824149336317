import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_product,
  get_product,
  update_product,
} from "../../../../Controllers/Products/Products";
import forms from "../../../../Data/Forms/Products/Products/forms";
import headers from "../../../../Data/Forms/Products/Products/headers";
import Loadercomponent from "../../../../Componets/Loader";
import { convert_to_file } from "../../../../Controllers/Global";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Productunittable from "../../../../Data/Forms/Products/Products/table";

const ProductsForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [details, setDetails] = useState([
    {
      id: "",
      name: null,
      options: "",
      quantity: "",
    },
  ]);

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const focus = useRef([]);

  const validateDetails = (data) => {
    if (data?.length > 0) {
      let total = data?.length;
      let count = 0;

      let validated = [];
      data?.map((v, i) => {
        if (!v?.name && data?.length > 1) {
          document.getElementById(`name${i}`).textContent = `Name is required`;
          if (i == count) {
            focus.current[`name${i}`].current.focus();
          }
        } else {
          document.getElementById(`name${i}`).textContent = "";
          validated?.push(v);
          count++;
        }
      });

      if (total == count) {
        return { status: true, data: validated };
      } else {
        return { status: false, data: validated };
      }
    } else {
      return { status: true, data: data };
    }
  };

  const formdata = async (data) => {
    if (data?.status) {
      const validatedetails = validateDetails(details);

      if (validatedetails?.status) {
        setButtonLoader(true);

        const product = id
          ? await update_product({ ...data?.data, id: id, details: details })
          : await create_product({ ...data?.data, details: details });

        if (product?.status) {
          if (id) {
            navigate(-1);
          }
          toast?.success(product?.message);
          setButtonLoader(false);
        } else {
          toast?.error(product?.message);
          setButtonLoader(false);
        }
      }
    }
  };

  const handleData = async (product) => {
    let image = product?.image ? product?.image : "";
    let file = await convert_to_file(image);
    let name = product?.name ? product?.name : "";
    let barcode = product?.barcode ? product?.barcode : "";

    let unit = product?.unit?._id ? product?.unit?._id : null;
    let category = product?.category?._id ? product?.category?._id : null;
    let brand = product?.brand?._id ? product?.brand?._id : null;

    let stock = product?.stock ? product?.stock : "";
    let expiry = product?.expiry ? product?.expiry : "";

    let type = product?.type ? product?.type : 0;
    let status = product?.status ? product?.status : 0;

    let unit_option = product?.unit?._id
      ? [{ value: product?.unit?._id, label: product?.unit?.name }]
      : null;
    let category_option = product?.category?._id
      ? [{ value: product?.category?._id, label: product?.category?.name }]
      : null;
    let brand_option = product?.brand?._id
      ? [{ value: product?.brand?._id, label: product?.brand?.name }]
      : null;

    values[0].options[0].form.value = file;
    values[0].options[1].form.value = name;
    values[0].options[2].form.value = barcode;

    values[1].options[0].form.value = unit;
    values[1].options[0].form.options = unit_option;
    values[1].options[1].form.value = category;
    values[1].options[1].form.options = category_option;
    values[1].options[2].form.value = brand;
    values[1].options[2].form.options = brand_option;

    values[2].options[0].form.value = stock;
    values[2].options[1].form.value = expiry;

    values[3].options[0].form.value = type;

    values[4].options[0].form.value = status;

    if (product?.product_units_details?.length > 0) {
      let product_unit_details = [];
      product?.product_units_details?.map((v, i) => {
        product_unit_details?.push({
          id: v?._id,
          name: v?.name?._id,
          options: [{ value: v?.name?._id, label: v?.name?.name }],
          quantity: v?.quantity,
        });
      });
      setDetails([...product_unit_details]);
    }

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const product = await get_product(data);
      if (product?.status) {
        handleData(product?.data);
      } else {
        toast?.error(product?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={1} title={"Units"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={false} />
              <Productunittable
                details={details}
                setDetails={setDetails}
                ref={focus}
              />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default ProductsForm;
