import React from "react";
import ReactApexChart from "react-apexcharts";

const Piechart = () => {
  const series = [1, 0, 0, 0];
  const options = {
    chart: {
      type: "donut",
      fontFamily: "Manage Medium",
    },
    labels: ["Product", "Return", "Out of Stock", "Expire"],
    colors: ["#00E396", "#008FFB", "#FEB019", "#FF4560"],
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "16px",
        fontWeight: "300",
        colors: ["#FFFFFF"],
      },
      dropShadow: {
        enabled: false,
      },
      formatter: function (val) {
        return val.toFixed(0) + "%";
      },
    },
    legend: {
      position: "right",
      fontSize: "16px",
      fontWeight: "100",
      offsetY: 100,
      markers: {
        width: 12,
        height: 12,
        radius: 12,
        offsetX: -20,
      },
      itemMargin: {
        horizontal: 10,
      },
      labels: {
        colors: ["#6b7280", "#6b7280", "#6b7280", "#6b7280"],
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
            fontSize: "14px",
          },
        },
      },
    ],
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default Piechart;
