import React, { useCallback } from "react";
import Error from "../Error";
import { useDropzone } from "react-dropzone";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

const Uploadcomponent = React.forwardRef((props, focus) => {
  const { index, ind, val, form, setForm, getFormdata } = props;

  const onfiles = async (file) => {
    let data = [...form];

    data[index].options[ind].form.value = file;
    setForm(data);
    getFormdata(data);
    console.log(data);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      let file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      onfiles(file);
    },
    [props?.index, onfiles]
  );

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop,
      accept: {
        "image/jpeg": [],
        "image/jpg": [],
        "image/png": [],
      },
    });

  const deleteFile = () => {
    onfiles("");
  };

  const acceptedFileItems =
    form?.[index]?.options?.[ind]?.form?.value?.length > 0 &&
    form?.[index]?.options?.[ind]?.form?.value?.map((file) => (
      <div key={file.name}>
        {file?.type?.includes("image") ? (
          <div className="pt-1">
            <img
              src={file.preview}
              className="w-[100px] h-[100px] rounded-md"
              onLoad={() => {
                URL.revokeObjectURL(file.preview);
              }}
            />
          </div>
        ) : file?.type?.includes("pdf") ? (
          <div className="pt-1">
            <FilePdfOutlined className="text-8xl text-gray-300" />
          </div>
        ) : (
          ""
        )}
        <div className="text-xs text-gray-400 text-regular">
          {file.path} - {file.size} bytes
          <button
            type="button"
            className="text-xl text-medium text-red-500 hover:text-red-800 px-2"
            onClick={deleteFile}
          >
            <DeleteOutlined />
          </button>
        </div>
      </div>
    ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.name}>
      {errors.map((e) => (
        <li key={e.code} className="text-xs text-red-500">
          {e.message}
        </li>
      ))}
    </div>
  ));

  return (
    <React.Fragment>
      <section className="">
        {form?.[index]?.options?.[ind]?.form?.value?.length === 0 && (
          <div className="flex justify-center items-center ">
            <div
              {...getRootProps({
                className:
                  "text-center flex flex-col justify-center items-center text-regular text-gray-400 cursor-pointer border hover:border-[#3498db] rounded-lg p-2 h-full w-full",
              })}
            >
              <input {...getInputProps()} />
              <p>
                <CloudUploadOutlined
                  className=""
                  style={{ fontSize: "16px" }}
                />
              </p>
              <p>Upload</p>
              <p>Drag & Drop</p>
            </div>
          </div>
        )}
        <aside>{acceptedFileItems}</aside>
        <aside>{fileRejectionItems}</aside>
        <Error
          id={form[index].options[ind].form?.name?.concat(index + "" + ind)}
        />
      </section>
    </React.Fragment>
  );
});

export default Uploadcomponent;
