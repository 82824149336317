import countries from "../../../countries.json";

const forms = [
  {
    name: "Details",
    options: [
      {
        name: "Name",
        type: "input",
        form: {
          type: "text",
          name: "name",
          placeholder: "Customer name",
          class: "",
          value: "",
          required: false,
        },
      },
    ],
  },
  {
    name: "Contacts",
    options: [
      {
        name: "Email",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Customer email",
          class: "",
          value: "",
          required: false,
        },
      },
      {
        name: "Phone",
        type: "input",
        form: {
          type: "text",
          name: "phone",
          placeholder: "Customer phone",
          class: "",
          value: "",
          required: true,
        },
      },
    ],
  },
  {
    name: "Address",
    options: [
      {
        name: "Area",
        type: "input",
        form: {
          type: "text",
          name: "area",
          placeholder: "Customer area address",
          class: "",
          value: "",
        },
      },
      {
        name: "City",
        type: "input",
        form: {
          type: "text",
          name: "city",
          placeholder: "Customer city address",
          class: "",
          value: "",
        },
      },
      {
        name: "State",
        type: "input",
        form: {
          type: "text",
          name: "state",
          placeholder: "Customer state address",
          class: "",
          value: "",
        },
      },
      {
        name: "Country",
        type: "select",
        form: {
          value: "Oman",
          name: "country",
          allowClear: false,
          showSearch: true,
          placeholder: "Oman",
          options: countries?.map((value, index) => ({
            value: value?.country,
            label: value?.country,
          })),
          required: false,
        },
      },
    ],
  },
  {
    name: "Status",
    options: [
      {
        name: "Status",
        type: "select",
        form: {
          name: "status",
          allowClear: false,
          placeholder: "Status",
          value: 1,
          options: [
            {
              value: 1,
              label: "Active",
            },
            {
              value: 0,
              label: "In-active",
            },
          ],
          required: false,
        },
      },
    ],
  },
];

export default forms;
