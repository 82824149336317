import { AutoComplete, DatePicker, Form, Input, Select } from "antd";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import AddButton from "../../../../Componets/Buttons/add";
import { select_id, select_search } from "../../../../Controllers/Global";
import dayjs from "dayjs";
import moment from "moment";
import taxes from "../../../taxes.json";
import payment_types from "../../../payment_types.json";
import {
  get_product,
  get_product_unit_detail,
  get_product_barcode,
} from "../../../../Controllers/Products/Products";

const Purchaseordertable = React.forwardRef((props, ref) => {
  const { details, setDetails, totals, setTotals } = props;

  const handleSearch = async (e, index) => {
    let data = [...details];

    const select = {
      api: "get-all-products",
      search: e,
      status: 1,
    };

    const search = await select_search(select);

    if (search?.status) {
      let options = [];
      search?.data?.map((value, index) => {
        options?.push({ value: value?._id, label: value?.name });
      });

      data[index].options = options;

      setDetails(data);
    } else {
      toast?.error(search?.message);
    }
  };

  const handleBarcode = async (e, index) => {
    let data = [...details];

    console.log(e, "eeee", index);

    if (e) {
      let description_ids = [];

      data?.map((v, i) => {
        description_ids?.push(v?.description);
      });

      if (!description_ids?.includes(e)) {
        let params = {
          barcode: e,
        };
        const product = await get_product_barcode(params);

        console.log(product, "get_product_barcodeget_product_barcode");
        if (product?.status) {
          data[index].options = [
            { value: product?.data?._id, label: product?.data?.name },
          ];

          let product_description = product?.data?._id;
          let product_unit = product?.data?.unit?.name;
          let product_unit_details = product?.data?.product_units_details;

          data[index].description = product_description;

          data[index].unit = product_unit;
          data[index].unit_details_options = product_unit_details;
          setDetails(data);
        } else {
          toast?.error("Product not found");
        }
      } else {
        let selected_index = description_ids?.indexOf(e);
        let quantity = data[selected_index].quantity
          ? data[selected_index].quantity
          : 0;

        data[selected_index].quantity = parseFloat(quantity) + parseFloat(1);
        setDetails(data);
      }
    } else {
      data[index].id = "";
      data[index].description = null;
      data[index].unit = "";
      data[index].unit_details_options = null;
      data[index].quantity = "";
      data[index].purchase = "";
      data[index].purchase_price = "";
      data[index].purchase_options = null;
      data[index].delivered = "";
      data[index].tax = 0;
      data[index].free = "";
      data[index].barcode = "";
      data[index].price_per_unit = "";
      data[index].sale_price = "";
      data[index].expiry = "";
      data[index].tax_amount = "";
      data[index].total = 0;
      setDetails(data);
    }
  };

  const handleDescription = async (e, index) => {
    let data = [...details];

    if (e) {
      let description_ids = [];

      data?.map((v, i) => {
        description_ids?.push(v?.description);
      });

      if (!description_ids?.includes(e)) {
        let params = {
          id: e,
        };
        const product = await get_product(params);

        let product_description = product?.data?._id;
        let product_unit = product?.data?.unit?.name;
        let product_unit_details = product?.data?.product_units_details;

        data[index].description = product_description;
        data[index].unit = product_unit;
        data[index].unit_details_options = product_unit_details;
        console.log(product?.data?.product_unit_details, product?.data);
        setDetails(data);
      } else {
        let selected_index = description_ids?.indexOf(e);
        let quantity = data[selected_index].quantity
          ? data[selected_index].quantity
          : 0;

        data[selected_index].quantity = parseFloat(quantity) + parseFloat(1);
        setDetails(data);
      }
    } else {
      data[index].id = "";
      data[index].description = null;
      data[index].unit = "";
      data[index].unit_details_options = null;
      data[index].quantity = "";
      data[index].purchase = "";
      data[index].purchase_price = "";
      data[index].purchase_options = null;
      data[index].delivered = "";
      data[index].tax = 0;
      data[index].free = "";
      data[index].barcode = "";
      data[index].price_per_unit = "";
      data[index].sale_price = "";
      data[index].expiry = "";
      data[index].tax_amount = "";
      data[index].total = 0;
      setDetails(data);
    }
  };

  const handleUnit = async (e, index) => {
    let data = [...details];

    if (e) {
      let params = {
        id: e,
      };
      const unit = await get_product_unit_detail(params);

      if (unit?.status) {
        data[index].unit = unit?.data?._id;
        data[index].quantity = "";
        data[index].purchase = "";
        data[index].purchase_price = "";
        data[index].purchase_options = null;
        data[index].delivered = "";
        data[index].tax = 0;
        data[index].free = "";
        data[index].barcode = "";
        data[index].price_per_unit = "";
        data[index].sale_price = "";
        data[index].expiry = "";
        data[index].tax_amount = "";
        data[index].total = 0;

        setDetails(data);
      } else {
        toast?.error("Unit not found");
      }
    }
  };

  const handlePurchasePriceSearch = async (e, index) => {
    let data = [...details];

    if (data[index].description) {
      const select = {
        api: "get-all-purchase-details",
        id: data[index].description,
      };

      const search = await select_id(select);
      if (search?.status) {
        let options = [];
        search?.data?.map((value, index) => {
          options?.push({
            value: JSON?.stringify(value),
            label:
              "" +
              value?.purchase_price?.toFixed(3) +
              " (" +
              value?.purchase?.supplier?.name +
              ", " +
              moment?.(value?.purchase?.date)?.format?.("DD/MM/YYYY") +
              ", #PO" +
              value?.purchase?.number +
              ")",
          });
        });

        data[index].purchase_options = options;
        setDetails(data);
      } else {
        toast?.error(search?.message);
      }
    }
  };

  const handleTotal = (e, index, key) => {
    console.log(e);
    let name = key ? key : e?.target?.name;
    let value = key && e ? e : !key && e?.target?.value ? e?.target?.value : "";

    if (value >= 0) {
      let data = [...details];

      let unit_quantity = data[index].unit_details_options?.find(
        (unit_detail) => unit_detail?.value == data[index].unit
      )?.quantity;

      if (
        name == "quantity" &&
        parseFloat(unit_quantity) > 1 &&
        parseFloat(value) >= parseFloat(unit_quantity)
      ) {
        toast?.error(`Unit quantity ${unit_quantity}`);
      } else {
        let data_purchase_price = data[index]?.purchase_price
          ? data[index]?.purchase_price
          : 0;
        let data_quantity = data[index]?.quantity ? data[index]?.quantity : 0;
        let data_deliverd = data[index]?.delivered ? data[index]?.delivered : 0;
        let data_free = data[index]?.free ? data[index]?.free : 0;
        let data_tax = data[index]?.tax ? data[index]?.tax : 0;

        let purchase_price =
          name === "purchase_price" ? value : data_purchase_price;
        let quantity = name === "quantity" ? value : data_quantity;
        let delivered = name === "quantity" ? "" : data_deliverd;
        let free = name === "free" ? value : data_free;
        let tax = name === "tax" ? value : data_tax;

        console.log();

        let tax_amount = tax
          ? parseFloat(purchase_price) *
            parseFloat(quantity ? quantity : 0) *
            (parseFloat(tax) / 100)
          : 0;

        let total =
          parseFloat(purchase_price) * parseFloat(quantity ? quantity : 0) +
          parseFloat(tax_amount);

        let price_per_unit =
          parseFloat(total ? total : 0) /
          (parseFloat(quantity ? quantity : 0) + parseFloat(free ? free : 0));

        if (unit_quantity) {
          value = parseInt(value);
        }

        data[index][name] = value;
        data[index].delivered = delivered ? delivered : "";
        data[index].tax = tax ? tax : 0;
        data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;
        data[index].total = total ? total?.toFixed(3) : 0;
        data[index].tax_amount = tax_amount ? tax_amount?.toFixed(3) : 0;
        data[index].price_per_unit = price_per_unit
          ? price_per_unit?.toFixed(3)
          : 0;

        let subtotal =
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.total),
            0
          ) -
          data?.reduce(
            (acc, currentValue) =>
              parseFloat(acc) + parseFloat(currentValue?.tax_amount),
            0
          );

        let taxamount = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.tax_amount),
          0
        );

        let grandtotal = data?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        );

        totals.subtotal = subtotal;
        totals.taxamount = taxamount;
        totals.total = grandtotal;

        setDetails(data);
        setTotals({ ...totals });
      }
    }
  };

  const handleChange = (e, index, key) => {
    let data = [...details];

    data[index][e?.target?.name] = e?.target?.value;
    setDetails(data);
  };

  const handleSalePrice = (e, index) => {
    if (e?.target?.value >= 0) {
      let data = [...details];

      data[index].sale_price = e?.target?.value;
      setDetails(data);
    }
  };

  const handleUnitDelivered = (index) => {
    let data = [...details];

    if (data?.[index]?.unit_details_options?.length > 0) {
      return data[index].unit_details_options.map((v, i) => {
        let unit_name = v?.label ? v?.label : "";
        let unit_quantity = v?.quantity ? v?.quantity : 0;
        let quantity = data?.[index]?.quantity ? data?.[index]?.quantity : 0;
        let delivered = data?.[index]?.delivered ? data?.[index]?.delivered : 0;
        let selected_unit_quantity = data?.[index]?.unit_details_options?.find(
          (unit_detail) => unit_detail?.value === data?.[index]?.unit
        )?.quantity;

        let total = parseFloat(unit_quantity) * parseFloat(delivered);

        if (unit_quantity) {
          if (parseFloat(selected_unit_quantity) < parseFloat(unit_quantity)) {
            return (
              <div key={i} className="text-sm">
                <div>{unit_name} :</div>
                <div>
                  {unit_quantity} * {delivered} = {total?.toFixed(3)}
                </div>
              </div>
            );
          }
        }
        return null;
      });
    } else {
      return "";
    }
  };

  const handleDate = (e, index, name) => {
    let data = [...details];
    data[index][name] = e?.$d;

    setDetails(data);
  };

  const handleDelivered = (e, index) => {
    let data = [...details];

    let data_quantity = data[index]?.quantity
      ? parseFloat(data[index]?.quantity)
      : 0;
    let data_delivered = e?.target?.value ? parseFloat(e?.target?.value) : 0;

    if (data_delivered <= data_quantity) {
      data[index][e?.target?.name] = e?.target?.value;
      setDetails(data);
    } else {
      let remaining_delivered =
        parseFloat(data_quantity) - parseFloat(data[index]?.delivered);

      toast?.error(`Quantity only ${data_quantity}`);
    }
  };

  const handleRemove = (index) => {
    let data = [...details];

    data?.splice(index, 1);

    setDetails(data);
  };

  const handleAdd = () => {
    setDetails((prevDetails) => [
      ...prevDetails,
      {
        id: "",
        description: null,
        unit: "",
        unit_details_options: null,
        quantity: "",
        purchase: "",
        purchase_price: "",
        purchase_options: null,
        delivered: "",
        tax: 0,
        free: "",
        barcode: "",
        price_per_unit: "",
        sale_price: "",
        expiry: "",
        tax_amount: "",
        total: 0,
      },
    ]);
  };

  const handleDelivery = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(e?.target?.value ? e?.target?.value : 0) -
        parseFloat(totals?.discount ? totals?.discount : 0);

      totals.delivery = e?.target?.value;
      totals.total = total;

      setTotals({ ...totals });
    }
  };

  const handleDiscount = (e) => {
    if (e?.target?.value >= 0) {
      let total_amount = parseFloat(
        details?.reduce(
          (acc, currentValue) =>
            parseFloat(acc) + parseFloat(currentValue?.total),
          0
        )
      );

      let total =
        parseFloat(total_amount ? total_amount : 0) +
        parseFloat(totals?.delivery ? totals?.delivery : 0) -
        parseFloat(e?.target?.value ? e?.target?.value : 0);

      if (e?.target?.value <= total_amount) {
        totals.discount = e?.target?.value;
        totals.total = total;
      } else {
        toast?.error(`Discount exceeding ${total_amount}`);
      }

      setTotals({ ...totals });
    }
  };

  const handleDeliveryStatus = (e) => {
    totals.delivery_status = e;

    if (!e) {
      totals.delivery_date = "";
    } else if (e == 1) {
      totals.delivery_date = "";
    }

    setTotals({ ...totals });
  };

  const handleDeliveryDate = (e) => {
    totals.delivery_date = e?.$d;
    setTotals({ ...totals });
  };

  const handlePaymentStatus = (e) => {
    totals.payment_status = e;

    if (!e) {
      totals.payment_types = null;
    }
    setTotals({ ...totals });
  };

  const handlePaymentTypes = (e) => {
    totals?.payment_types?.map((v, i) => {
      if (!e?.includes(v)) {
        totals.payments[v] = "";
      }
    });

    totals.payment_types = e;

    setTotals({ ...totals });
  };

  const handlePayments = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value ? e?.target?.value : "";

    if (e?.target?.value >= 0) {
      let data_Cash = totals?.payments?.Cash ? totals?.payments?.Cash : 0;
      let data_Cheque = totals?.payments?.Cheque ? totals?.payments?.Cheque : 0;
      let data_Debitcard = totals?.payments?.["Debit card"]
        ? totals?.payments?.["Debit card"]
        : 0;
      let data_Creditcard = totals?.payments?.["Credit card"]
        ? totals?.payments?.["Credit card"]
        : 0;
      let data_Banktransfer = totals?.payments?.["Bank transfer"]
        ? totals?.payments?.["Bank transfer"]
        : 0;
      let data_Onlinepayment = totals?.payments?.["Online payment"]
        ? totals?.payments?.["Online payment"]
        : 0;

      let Cash = name === "Cash" ? value : data_Cash;
      let Cheque = name === "Cheque" ? value : data_Cheque;
      let Debitcard = name === "Debit card" ? value : data_Debitcard;
      let Creditcard = name === "Credit card" ? value : data_Creditcard;
      let Banktransfer = name === "Bank transfer" ? value : data_Banktransfer;
      let Onlinepayment =
        name === "Online payment" ? value : data_Onlinepayment;

      let total =
        parseFloat(Cash) +
        parseFloat(Cheque) +
        parseFloat(Debitcard) +
        parseFloat(Creditcard) +
        parseFloat(Banktransfer) +
        parseFloat(Onlinepayment);

      let payment_total = total ? parseFloat(total) : 0;
      let grand_total = totals?.total ? parseFloat(totals?.total) : 0;

      if (payment_total <= grand_total) {
        totals.payments[name] = value;
        setTotals({ ...totals });
      } else {
        let previous_total =
          parseFloat(data_Cash) +
          parseFloat(data_Cheque) +
          parseFloat(data_Debitcard) +
          parseFloat(data_Creditcard) +
          parseFloat(data_Banktransfer) +
          parseFloat(data_Onlinepayment);

        let previous_remaining_amount =
          parseFloat(grand_total) - parseFloat(previous_total);

        toast?.error(`Payment exceeding ${grand_total}`);
      }
    }
  };

  useEffect(() => {
    let barcodeInput = ""; // Temporary storage for the barcode input

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        // Call the async function to handle the barcode lookup
        fetchProductByBarcode(barcodeInput);
        // Clear the temporary barcode input
        barcodeInput = "";
      } else {
        // Accumulate the key pressed to barcodeInput
        barcodeInput += event.key;
      }
    };

    const fetchProductByBarcode = async (barcode) => {
      try {
        let data = {
          barcode: barcode,
        };

        await handleBarcode(barcode, details?.length - 1);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    // Add event listener for keydown
    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            <td className="border p-4 text-black">S.No</td>
            <td className="border p-4 text-black">Description</td>
            <td className="border p-4 text-black">Unit</td>
            <td className="border p-4 text-black">Purchase Price</td>
            <td className="border p-4 text-black">Quantity</td>
            <td className="border p-4 text-black">Delivered</td>
            <td className="border p-4 text-black">Free</td>
            <td className="border p-4 text-black">Tax</td>
            <td className="border p-4 text-black">Total</td>
            <td className="border p-4 text-black">Delete</td>
          </tr>
        </thead>
        <tbody>
          {details?.map((value, index) => {
            return (
              <React.Fragment>
                <tr>
                  <td className="border p-4 text-center">{index + 1}</td>
                  <td className="border p-4">
                    <Select
                      className="w-[200px] text-regular"
                      name="description"
                      placeholder="Description"
                      allowClear={true}
                      showSearch={true}
                      filterOption={false}
                      value={value?.description}
                      options={value?.options}
                      onSearch={(e) => handleSearch(e, index)}
                      onClick={(e) => handleSearch("", index)}
                      onChange={(e) => handleDescription(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    {value?.unit}
                    {/* {value?.unit_details_options?.length > 0 ? (
                      <Select
                        className="w-[100px] text-regular"
                        name="description"
                        placeholder="Description"
                        allowClear={false}
                        showSearch={true}
                        filterOption={false}
                        value={value?.unit}
                        options={value?.unit_details_options}
                        onChange={(e) => handleUnit(e, index)}
                        autoComplete="off"
                      />
                    ) : (
                      value?.unit
                    )} */}
                  </td>
                  <td className="border p-4 text-center">
                    <div>
                      <div>
                        <AutoComplete
                          className="w-[325px] text-regular"
                          name="purchase_price"
                          placeholder="Purchase price"
                          allowClear={true}
                          showSearch={true}
                          filterOption={false}
                          options={value?.purchase_options}
                          value={value?.purchase_price}
                          onClick={(e) => handlePurchasePriceSearch("", index)}
                          onChange={(e) =>
                            handleTotal(e, index, "purchase_price")
                          }
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full text-regular"
                      name="quantity"
                      placeholder="Quantity"
                      value={value?.quantity}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                      required="required"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full text-regular"
                      name="delivered"
                      placeholder="Delivered"
                      value={value?.delivered}
                      onChange={(e) => handleDelivered(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Input
                      type="number"
                      className="w-full text-regular"
                      name="free"
                      placeholder="Free"
                      value={value?.free}
                      onChange={(e) => handleTotal(e, index)}
                      autoComplete="off"
                    />
                  </td>
                  <td className="border p-4 text-center">
                    <Select
                      className="w-full text-regular"
                      name="tax"
                      placeholder="Tax"
                      options={taxes}
                      value={value?.tax}
                      onChange={(e) => handleTotal(e, index, "tax")}
                    />
                  </td>
                  <td className="border p-4 text-center">
                    {parseFloat?.(value?.total)?.toFixed(3)}
                  </td>
                  <td className="border p-4 text-center">
                    <button
                      type="button"
                      className="text-lg text-red-500 cursor-pointer hover:text-red-800"
                      onClick={() => handleRemove(index)}
                    >
                      <DeleteOutlined />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="border p-4 text-center"></td>
                  <td className="border p-4">
                    <div className="pb-2">Barcode</div>
                    <Input
                      type="text"
                      className="w-full text-regular"
                      name="barcode"
                      placeholder="Barcode"
                      value={value?.barcode}
                      onChange={(e) => handleChange(e, index)}
                      autoComplete="off"
                      // required="required"
                    />
                  </td>
                  <td className="border p-4">
                    <div className="pb-2">Price/Unit</div>
                    <div className="pt-2">{value?.price_per_unit}</div>
                  </td>
                  <td className="border p-4">
                    <div className="pb-2">Sale price</div>
                    <Input
                      type="number"
                      className="w-full text-regular"
                      name="sale_price"
                      placeholder="Sale price"
                      value={value?.sale_price}
                      onChange={(e) => handleSalePrice(e, index)}
                      autoComplete="off"
                      required="required"
                    />
                  </td>
                  <td className="border p-4">
                    {value?.unit_details_options?.map((v, i) => {
                      return (
                        <div>
                          <div className="pb-2">
                            {v?.name?.name}({v?.quantity})
                          </div>
                        </div>
                      );
                    })}
                  </td>
                  <td className="border p-4">
                    {value?.unit_details_options?.map((v, i) => {
                      return (
                        <div className={i > 0 ? "pt-1" : ""}>
                          {v?.name?.name}({v?.quantity}*
                          {value?.delivered ? value?.delivered : 0}=
                          {v?.quantity && value?.delivered
                            ? (
                                parseFloat(v?.quantity) *
                                parseFloat(value?.delivered)
                              )?.toFixed(3)
                            : "0"}
                          )
                          {/* <Input
                            type="number"
                            className={"w-full text-regular"}
                            name="quantity"
                            placeholder="Quantity"
                            value={value?.delivered}
                            onChange={(e) => handleUnitDelivered(e, index)}
                            autoComplete="off"
                            required="required"
                          /> */}
                        </div>
                      );
                    })}
                  </td>
                  <td className="border p-4">
                    <div className="pb-2">Expiry</div>
                    <DatePicker
                      type="number"
                      className="w-full text-regular"
                      name="expiry"
                      placeholder="Expiry"
                      value={value?.expiry_date && dayjs(value?.expiry_date)}
                      onChange={(e) => handleDate(e, index, "expiry_date")}
                      autoComplete="off"
                      // required="required"
                    />
                  </td>
                  <td className="border p-4">
                    <div className="pb-2">Taxamount</div>
                    <div>{value?.tax_amount}</div>
                  </td>
                  <td className="border p-4 text-center" colSpan={2}></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Subtotal</td>
            <td className="border p-4" colSpan={2}>
              {totals?.subtotal > 0 ? totals?.subtotal?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Tax&nbsp;amount</td>
            <td className="border p-4" colSpan={2}>
              {totals?.taxamount > 0 ? totals?.taxamount?.toFixed(3) : "0.000"}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Discount&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Discount amount"
                name="discount"
                value={totals?.discount}
                autoComplete="off"
                onChange={handleDiscount}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Delivery&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <Input
                type="number"
                className="w-full"
                placeholder="Delivery charges"
                name="delivery"
                value={totals?.delivery}
                autoComplete="off"
                onChange={handleDelivery}
              />
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Delivery status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <div>
                  <Select
                    className="w-full"
                    name="tax"
                    placeholder="Delivery status"
                    value={totals?.delivery_status}
                    options={[
                      {
                        value: 0,
                        label: "Pending",
                      },
                      {
                        value: 1,
                        label: "Partial",
                      },
                      {
                        value: 2,
                        label: "Delivered",
                      },
                    ]}
                    onChange={handleDeliveryStatus}
                  />
                </div>
                {totals?.delivery_status == 2 && (
                  <div className="pt-4">
                    <DatePicker
                      type="number"
                      className="w-full"
                      placeholder="Delivered date"
                      name="delivery"
                      value={
                        totals?.delivery_date && dayjs(totals?.delivery_date)
                      }
                      onChange={handleDeliveryDate}
                      autoComplete="off"
                    />
                  </div>
                )}
              </div>
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4">Payment status&nbsp;</td>
            <td className="border p-4" colSpan={2}>
              <div>
                <Select
                  className="w-full"
                  name="tax"
                  placeholder="Payment status"
                  value={totals?.payment_status}
                  options={[
                    {
                      value: 0,
                      label: "Unpaid",
                    },
                    {
                      value: 1,
                      label: "Partial",
                    },
                    {
                      value: 2,
                      label: "Paid",
                    },
                  ]}
                  onChange={handlePaymentStatus}
                />
              </div>
              {totals?.payment_status == 1 || totals?.payment_status == 2 ? (
                <div className="pt-4">
                  <Select
                    mode="multiple"
                    className="w-full"
                    name="tax"
                    placeholder="Payment status"
                    value={totals?.payment_types}
                    options={payment_types}
                    onChange={handlePaymentTypes}
                  />
                </div>
              ) : (
                ""
              )}
              {totals?.payment_types?.map((value, index) => {
                return (
                  <div className="pt-4">
                    <Input
                      type="number"
                      className="w-full"
                      placeholder={value}
                      name={value}
                      value={totals?.payments?.[value]}
                      onChange={handlePayments}
                      autoComplete="off"
                      required="required"
                    />
                  </div>
                );
              })}
            </td>
          </tr>
          <tr>
            <td className="border p-4" colSpan={7}></td>
            <td className="border p-4 text-black text-medium">Total</td>
            <td className="border p-4 text-black text-medium" colSpan={2}>
              {totals?.total > 0 ? totals?.total?.toFixed(3) : "0.000"}
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-end p-3">
        <AddButton onClick={handleAdd} />
      </div>
    </div>
  );
});

export default Purchaseordertable;
