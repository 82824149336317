import React from "react";

const BarcodeIcon = (props) => {
  let height = props?.height ? props?.height : 20;
  let width = props?.width ? props?.width : 20;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 682.667 682.667"
    >
      <g>
        <defs>
          <clipPath id="a" clipPathUnits="userSpaceOnUse">
            <path
              d="M0 512h512V0H0Z"
              fill="currentColor"
              opacity="1"
              data-original="#000000"
            ></path>
          </clipPath>
        </defs>
        <g
          clip-path="url(#a)"
          transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        >
          <path
            d="M0 0v60c0 11.046 8.954 20 20 20h60"
            transform="translate(15 356)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0h-60c-11.046 0-20 8.954-20 20v60"
            transform="translate(95 76)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-60c0-11.046-8.954-20-20-20h-60"
            transform="translate(497 156)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0h60c11.046 0 20-8.954 20-20v-60"
            transform="translate(417 436)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-238"
            transform="translate(75 375)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-158"
            transform="translate(135 375)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-238"
            transform="translate(195 375)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-158"
            transform="translate(255 375)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-158"
            transform="translate(315 375)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-158"
            transform="translate(375 375)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v-238"
            transform="translate(435 375)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v17"
            transform="translate(135 137)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v17"
            transform="translate(255 137)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v17"
            transform="translate(315 137)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
          <path
            d="M0 0v17"
            transform="translate(375 137)"
            fill="none"
            stroke="currentColor"
            stroke-width="30"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            stroke-dasharray="none"
            stroke-opacity=""
            data-original="#000000"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default BarcodeIcon;
