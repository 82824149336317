import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Products/Units/tables";
import headers from "../../../../Data/Tables/Products/Units/headers";
import { get_all_product_units } from "../../../../Controllers/Products/Units";
import Loadercomponent from "../../../../Componets/Loader";

const UnitsTable = () => {
  const [loader, setLoader] = useState(false);
  const [body, setBody] = useState();

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const params = {
        search: tabledata?.search,
        status: tabledata?.status,
      };

      const units = await get_all_product_units(params);

      if (units?.status) {
        let data = [];

        units?.data?.map((value, index) => {
          data?.push({
            Name: value?.name,
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/products/units/edit/${value?._id}`}
                id={value?._id}
                api="delete-product-unit"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(units?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default UnitsTable;
