const headers = {
  name: "Product category",
  navigate: {
    path: [
      {
        name: "Categories",
        path: "/app/products/categories",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
