import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_inventory,
  get_inventory,
  update_inventory,
} from "../../../../Controllers/Inventory/Batch";
import forms from "../../../../Data/Forms/Inventory/Batch/forms";
import headers from "../../../../Data/Forms/Inventory/Batch/headers";
import Loadercomponent from "../../../../Componets/Loader";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Inventoryunittable from "../../../../Data/Forms/Inventory/Batch/table";

const InventoryBatchForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [details, setDetails] = useState([
    {
      id: "",
      name: null,
      options: "",
      quantity: "",
      stock: "",
    },
  ]);

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      const inventory = id
        ? await update_inventory({ ...data?.data, id: id })
        : await create_inventory({ ...data?.data });

      if (inventory?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(inventory?.message);
        setButtonLoader(false);
      } else {
        toast?.error(inventory?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (inventory) => {
    let number = inventory?.number ? inventory?.number : "";
    let product = inventory?.product?._id ? inventory?.product?._id : "";
    let barcode = inventory?.barcode ? inventory?.barcode : "";
    let purchase_price = inventory?.purchase_price
      ? inventory?.purchase_price
      : 0;
    let sale_price = inventory?.sale_price ? inventory?.sale_price : 0;
    let tax = inventory?.tax ? inventory?.tax : 0;
    let stock = inventory?.stock ? inventory?.stock : 0;
    let manufacture_date = inventory?.manufacture_date
      ? inventory?.manufacture_date
      : "";
    let expiry_date = inventory?.expiry_date ? inventory?.expiry_date : "";
    let status = inventory?.status ? inventory?.status : 0;

    let product_option = inventory?.product?._id
      ? [{ value: inventory?.product?._id, label: inventory?.product?.name }]
      : null;

    values[0].options[0].form.value = number;
    values[0].options[1].form.value = product;
    values[0].options[1].form.options = product_option;
    values[0].options[2].form.value = barcode;

    values[1].options[0].form.value = purchase_price;
    values[1].options[1].form.value = sale_price;
    values[1].options[2].form.value = tax;

    values[2].options[0].form.value = stock;

    values[3].options[0].form.value = manufacture_date;
    values[3].options[1].form.value = expiry_date;

    values[4].options[0].form.value = status;

    if (inventory?.inventory_unit_details?.length > 0) {
      let inventory_unit_details = [];
      inventory?.inventory_unit_details?.map((v, i) => {
        inventory_unit_details?.push({
          id: v?._id,
          name: v?.name?._id,
          options: [{ value: v?.name?._id, label: v?.name?.name }],
          quantity: v?.quantity,
          stock: v?.stock,
        });
      });
      setDetails([...inventory_unit_details]);
    }

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const inventory = await get_inventory(data);
      if (inventory?.status) {
        handleData(inventory?.data);
      } else {
        toast?.error(inventory?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        >
          <Bodycomponent>
            <Titlecomponent index={1} title={"Units"} />
            <Formdata>
              <Headercomponent index={0} name={"Details"} required={false} />
              <Inventoryunittable details={details} setDetails={setDetails} />
            </Formdata>
          </Bodycomponent>
        </Forms>
      </Loadercomponent>
    </Header>
  );
};

export default InventoryBatchForm;
