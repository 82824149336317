import moment from "moment";
import React from "react";

const ThermalInvoice = React.forwardRef((props, ref) => {
  const { values } = props;

  return (
    <div ref={ref} style={styles.invoice}>
      <div style={styles.header}>
        <h2 className="text-base">{values?.branch?.name}</h2>
        <p>{values?.branch?.area}</p>
        <p>
          {values?.branch?.city ? values?.branch?.city + ", " : ""}
          {values?.branch?.state}
        </p>
        <p>{values?.branch?.country}</p>
      </div>
      <div style={styles.details}>
        <p>Date:{moment?.(values?.date)?.format("DD-MM-YY")}</p>
        <p>Invoice : {`#INV-${values?.number}`}</p>
      </div>
      <div className="mb-2 border-t border-dashed border-black" />
      <div style={styles.items}>
        <table style={styles.table}>
          <thead>
            <tr className="border-b border-dashed border-black">
              <th className="p-1" style={styles.th}>
                Item
              </th>
              <th className="p-1" style={styles.th}>
                Unit
              </th>
              <th className="p-1" style={styles.th}>
                QTY
              </th>
              <th className="p-1" style={styles.th}>
                PRC
              </th>
              <th className="px-1" style={styles.th}>
                TAX
              </th>
              <th className="p-1" style={styles.th}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {values?.details?.map((item, index) => (
              <React.Fragment>
                <tr key={index}>
                  <td className="p-1" style={styles.td} colSpan={5}>
                    <div> {item?.description?.product?.name}&nbsp;</div>
                    {item?.description?.expiry_date
                      ? "Expiry : " +
                        moment?.(item?.description?.expiry_date)?.format(
                          "DD-MM-YY"
                        )
                      : ""}
                  </td>
                </tr>
                <tr key={index}>
                  <td colSpan={1}></td>
                  <td className="p-1" style={styles.td}>
                    {item?.unit}
                  </td>
                  <td className="p-1" style={styles.td}>
                    &nbsp;{item?.quantity?.toFixed(3)}
                  </td>
                  <td className="p-1" style={styles.td}>
                    <div>{item?.sale_price?.toFixed?.(3)}</div>
                  </td>
                  <td className="p-1" style={styles.td}>
                    <div>{item?.tax}%</div>
                    {/* <div>{item?.tax_amount?.toFixed?.(3)}</div> */}
                  </td>
                  <td className="p-1" style={styles.td}>
                    &nbsp;{item?.total?.toFixed?.(3)}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mb-2 border-t border-dashed border-black" />
      <div
        style={styles?.total}
        className="pr-2 border-t border-dashed border-black pt-2"
      >
        <p>Subtotal: {values?.subtotal?.toFixed(3)}</p>
        <p>Taxamount: {values?.taxamount?.toFixed(3)}</p>
        <p>
          Discount:
          {values?.discount ? "-" + values?.discount?.toFixed(3) : "0.000"}
        </p>
        <p>
          Delivery:
          {values?.delivery ? "+" + values?.delivery?.toFixed(3) : "0.000"}
        </p>
        <h3 className="text-medium">Total: OMR{values?.total?.toFixed(3)}</h3>
      </div>
      <div className="mt-2 border-t border-dashed border-black" />
      <div className="mt-2 border-t border-dashed border-black" />
    </div>
  );
});

const styles = {
  invoice: {
    width: "80mm", // Typical width for thermal printers
    fontFamily: "monospace",
    fontSize: "12px",
    padding: "10px",
    // border: "1px solid #000",
  },
  header: {
    textAlign: "center",
    marginBottom: "10px",
  },
  details: {
    marginBottom: "10px",
  },
  items: {
    marginBottom: "10px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    // borderBottom: "1px solid #000",
    // borderStyle: "dotted",
    textAlign: "left",
  },
  td: {
    padding: "5px 0",
  },
  total: {
    textAlign: "right",
  },
};

export default ThermalInvoice;
