const headers = {
  name: "Product unit",
  navigate: {
    path: [
      {
        name: "Units",
        path: "/app/products/units",
      },
      {
        name: "Add",
        path: "",
      },
    ],
  },
};

export default headers;
