import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ThermalInvoice from "./ThermalInvoice";
import Barcode from "react-barcode";
import products from "../../../Data/products.json";
import inventory from "../../../Data/inventory.json";
import { PrinterOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { get_invoice } from "../../../Controllers/Sales/Orders";
import toast from "react-hot-toast";

const BillingView = (props) => {
  let { id } = useParams();
  const { details } = props;

  const a4ref = useRef();

  const invoiceRef = useRef();

  const barcodeRef = useRef();

  const handleA4Print = useReactToPrint({
    content: () => a4ref.current,
    pageStyle: `
      @page {
        size: A4;
        margin: 50px;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          font-family: 'monospace';
          background-color: white;
          margin: 0;
          padding: 0;
        }
      }
    `,
  });

  // const handlePrint = useReactToPrint({
  //   content: () => invoiceRef.current,
  //   pageStyle: `
  //     @page {
  //       size: 80mm auto;
  //       margin: 0mm;
  //     }
  //     @media print {
  //       body {
  //         -webkit-print-color-adjust: exact;
  //         font-family: 'monospace';
  //       }
  //     }
  //   `,
  // });

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
    pageStyle: `
      @media print {
        font-family: 'monospace';
      }
    `,
  });

  const handleBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const items = [
    {
      name: "LAMICTAL LIQUI-TABS 5MG",
      quantity: 1,
      Strip: 0,
      tablets: 0,
      price: 1.95,
      tax: 0.345,
      total: 2.047,
    },
    {
      name: "HAVRIX VACCINE 1440",
      quantity: 3,
      Strip: 0,
      tablets: 0,
      price: 6.95,
      tax: 1.043,
      total: 21.893,
    },
    {
      name: "ENGERIX VACCINE PAD10MCG/VIAL",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 3.95,
      tax: 0.198,
      total: 4.147,
    },
    {
      name: "ZESTRIL TABLETS 5MG",
      quantity: 0,
      strips: 3,
      tablets: 0,
      price: 0.825,
      tax: 0.124,
      total: 2.599,
    },
    {
      name: "CLARITINE TABLETS 10MG",
      quantity: 0,
      strips: 2,
      tablets: 5,
      price: 1.413,
      tax: 0.155,
      total: 3.246,
    },
    {
      name: "ALLOFERIN INJ 10MG/2ML",
      quantity: 1,
      strips: 0,
      tablets: 0,
      price: 7.65,
      tax: 0.383,
      total: 8.033,
    },
  ];

  const total = items.reduce((acc, item) => acc + item.total, 0);

  const getproduct = (id) => {
    let product = {};

    products?.map((value, index) => {
      if (id == value?.id) {
        product = value;
      }
    });

    console.log(product);

    return product;
  };

  const [loader, setLoader] = useState(false);
  const [values, setValues] = useState();

  const handleData = (invoice) => {
    setValues(invoice);
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const invoice = await get_invoice(data);
      if (invoice?.status) {
        handleData(invoice?.data);
        console.log(invoice?.data, "invoice?.datainvoice?.datainvoice?.data");
      } else {
        toast?.error(invoice?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <div className="pt-2" onClick={handlePrint}>
        Print Invoice <PrinterOutlined className="text-lg" />
      </div>
      <div className="flex pb-5">
        <ThermalInvoice
          ref={invoiceRef}
          values={values}
          items={items}
          total={total}
        />
      </div>
    </div>
  );
};

export default BillingView;
