import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import toast from "react-hot-toast";
import Header from "../../../../Componets/Header";
import {
  create_invoice,
  get_invoice,
  update_invoice,
} from "../../../../Controllers/Sales/Orders";
import forms from "../../../../Data/Forms/Sales/Order/forms";
import headers from "../../../../Data/Forms/Sales/Order/headers";
import Loadercomponent from "../../../../Componets/Loader";
import Bodycomponent from "../../../../Componets/Formcomponents/body";
import Titlecomponent from "../../../../Componets/Formcomponents/title";
import Formdata from "../../../../Componets/Formcomponents/formdata";
import Headercomponent from "../../../../Componets/Formcomponents/header";
import Invoicetable from "../../../../Data/Forms/Sales/Order/table";
import BillingView from "../../../Billing/View";
import BillingPos from "../../../../Data/Forms/Billing/pos";

const InvoicesForm = () => {
  let { id } = useParams();
  let location = useLocation();

  const path = "/app/billing";

  if (location?.pathname == path) {
    headers.name = "Billing";
    headers.navigate.path = [
      {
        name: "Biling",
        path: "/app/billing",
      },
      {
        name: "Add",
        path: "",
      },
    ];
  } else {
    headers.name = "Invoice";
    headers.navigate.path = [
      {
        name: "Invoice",
        path: "/app/invoice",
      },
      {
        name: "Add",
        path: "",
      },
    ];
  }

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const onSubmit = (e) => {
    e?.preventDefault();
  };

  const [options, setOptions] = useState();
  const [details, setDetails] = useState([]);
  const [totals, setTotals] = useState({
    subtotal: "0.000",
    taxamount: "0.000",
    discount: 0,
    delivery: 0,
    delivery_status: 0,
    delivery_date: "",
    payment_status: 0,
    payment_types: null,
    payments: {
      Cash: "",
      Cheque: "",
      "Debit card": "",
      "Credit card": "",
      "Bank transfer": "",
      "Online payment": "",
    },
    paid: 0,
    remaining: 0,
    total: "0.000",
  });

  const formdata = async (data) => {
    console.log(data);
    if (data?.status) {
      setButtonLoader(true);

      const invoice = id
        ? await update_invoice({
            ...data?.data,
            id: id,
            details: details,
            subtotal: totals?.subtotal,
            taxamount: totals?.taxamount,
            discount: totals?.discount,
            delivery: totals?.delivery,
            delivery_status: totals?.delivery_status,
            delivery_date: totals?.delivery_date,
            payment_status: totals?.payment_status,
            payment_types: totals?.payment_types,
            payments: totals?.payments,
            total: totals?.total,
          })
        : await create_invoice({
            ...data?.data,
            date: new Date(),
            details: details,
            subtotal: totals?.subtotal,
            taxamount: totals?.taxamount,
            discount: totals?.discount,
            delivery: totals?.delivery,
            delivery_status: totals?.delivery_status,
            delivery_date: totals?.delivery_date,
            payment_status: totals?.payment_status,
            payment_types: totals?.payment_types,
            payments: totals?.payments,
            total: totals?.total,
          });

      if (invoice?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(invoice?.message);
        setButtonLoader(false);
      } else {
        toast?.error(invoice?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = (invoice) => {
    let customer = invoice?.customer?._id ? invoice?.customer?._id : null;
    let number = invoice?.number ? invoice?.number : "";
    let date = invoice?.date ? invoice?.date : "";
    let due_date = invoice?.due_date ? invoice?.due_date : "";
    let status = invoice?.status ? invoice?.status : 0;

    let customer_option = invoice?.customer?._id
      ? [
          {
            value: invoice?.customer?._id,
            label: invoice?.customer?.name,
          },
        ]
      : null;

    values[0].options[0].form.value = customer;
    values[0].options[0].form.options = customer_option;
    values[0].options[1].form.value = number;
    values[0].options[2].form.value = date;
    values[0].options[3].form.value = due_date;
    values[0].options[4].form.value = status;

    let data = [];
    invoice?.details?.map((value, index) => {
      data?.push({
        id: value?._id,
        description: value?.description?._id,
        options: [
          {
            value: value?.description?._id,
            label: value?.description?.product?.name,
          },
        ],
        unit: value?.unit,
        sale_price: value?.sale_price,
        quantity: value?.quantity,
        delivered: value?.delivered,
        discount: value?.discount,
        delivery: value?.delivery,
        tax: value?.tax,
        total: value?.total,
      });
    });

    totals.subtotal = invoice?.subtotal;
    totals.taxamount = invoice?.taxamount;
    totals.discount = invoice?.discount;
    totals.delivery = invoice?.delivery;
    totals.delivery_status = invoice?.delivery_status;
    totals.delivery_date = invoice?.delivery_date;
    totals.payment_status = invoice?.payment_status;
    totals.payment_types = JSON?.parse(invoice?.payment_types);
    totals.payments = JSON?.parse(invoice?.payments);
    totals.paid = invoice?.paid;
    totals.remaining = invoice?.remaining;
    totals.total = invoice?.total;

    setValues([...values]);
    setDetails([...data]);
    setTotals({ ...totals });
    setLoader(false);
  };

  const fetchData = async () => {
    if (id) {
      setLoader(true);

      const data = {
        id: id,
      };

      const invoice = await get_invoice(data);
      if (invoice?.status) {
        handleData(invoice?.data);
      } else {
        toast?.error(invoice?.message);
      }
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <BillingPos
          loader={loader}
          setLoader={setLoader}
          options={options}
          setOptions={setOptions}
          details={details}
          setDetails={setDetails}
          totals={totals}
          setTotals={setTotals}
          // form={values}
          // setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default InvoicesForm;
