import { MailOutlined } from "@ant-design/icons";

const forms = [
  {
    name: <div className="text-[#07b553] text-semi-bold text-2xl">Sign In</div>,
    className: "flex items-center min-h-screen w-full",
    body: {
      className: "bg-white p-4 border rounded-lg p-8 w-[20%] mx-auto",
    },
    formdata: {
      className: "text-sm text-medium text-gray-500 rounded-md",
    },
    options: [
      {
        name: "",
        type: "input",
        form: {
          type: "text",
          name: "email",
          placeholder: "Email",
          className: "",
          size: "large",
          suffix: <MailOutlined className="text-gray-500" />,
          value: "",
          required: true,
        },
      },
      {
        name: "",
        type: "password",
        form: {
          type: "text",
          name: "password",
          placeholder: "Password",
          className: "",
          size: "large",
          value: "",
          required: true,
        },
      },
    ],
    button: "",
  },
];

export default forms;
