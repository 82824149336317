const headers = {
  name: "Invoices",
  components: [
    {
      type: "search",
      name: "search",
      placeholder: "Search",
      value: "",
    },
    {
      type: "select",
      name: "sort",
      placeholder: "Sort",
      allowClear: true,
      showSearch: false,
      filterOption: true,
      value: null,
      options: [
        { value: 0, label: "Price (Low-High)" },
        { value: 1, label: "Price (High-Low)" },
      ],
    },
    {
      type: "filter",
      name: "supplier",
      allowClear: true,
      showSearch: true,
      filterOption: false,
      placeholder: "Supplier",
      api: "get-all-suppliers",
      value: null,
      options: null,
    },
    {
      type: "filter",
      name: "status",
      placeholder: "Status",
      value: null,
      allowClear: true,
      showSearch: false,
      filterOption: true,
      options: [
        { value: 0, label: "Inactive" },
        { value: 1, label: "Active" },
      ],
    },
    {
      type: "range",
      name: "date",
      value: {
        start: "",
        end: "",
      },
    },
    {
      type: "range",
      name: "due_date",
      value: {
        start: "",
        end: "",
      },
    },
    {
      type: "add",
      path: "/app/sales/add",
    },
  ],
  navigate: {
    tabs: [
      {
        name: "Invoices",
        path: "",
      },
      // {
      //   name: "Quotes",
      //   path: "/app/sales/quotes",
      // },
      // {
      //   name: "Boqs",
      //   path: "/app/sales/boqs",
      // },
    ],
    total: [
      { value: 0, label: "Total" },
      { value: 0, label: "Active" },
      { value: 0, label: "Inactive" },
    ],
  },
};

export default headers;
