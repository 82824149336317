import React, { useEffect, useState } from "react";
import Header from "../../../Componets/Header";
import { useLocation } from "react-router-dom";
import inventory from "../../../Data/inventory.json";
import products from "../../../Data/products.json";

const InventoryReport = () => {
  let location = useLocation();

  const [reports, setReports] = useState();

  const headers = {
    name: "Inventory",
    components: [
      {
        type: "search",
        name: "search",
        placeholder: "Search",
        value: "",
      },
      {
        type: "range",
        name: "date",
        value: {
          start: "",
          end: "",
        },
      },
    ],
  };

  const get_product = (id) => {
    let product = "";
    products?.map((value, index) => {
      if (id == value?.id) {
        product = value?.name;
      }
    });

    return product;
  };

  const fetchData = (tableData) => {
    let data = [];

    if (tableData) {
      tableData?.map((value, index) => {
        data?.push({
          name: get_product(value?.product),
          batch: value?.batch_number,
          stock: value?.stock,
          strips: value?.strips,
          tablets: value?.tablets,
          purchase: value?.purchase_price,
          sale: value?.sale_price,
          tax: value?.tax,
          expiry: value?.expiry_date,
        });
      });
    } else {
      inventory?.map((value, index) => {
        data?.push({
          name: get_product(value?.product),
          batch: value?.batch_number,
          stock: value?.stock,
          strips: value?.strips,
          tablets: value?.tablets,
          purchase: value?.purchase_price,
          sale: value?.sale_price,
          tax: value?.tax,
          expiry: value?.expiry_date,
        });
      });
    }

    setReports(data);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const formdata = async (data) => {
    console.log(data);

    let tableData = [...inventory];

    let search = data?.search?.toLowerCase();
    let date = data?.date;

    if (search) {
      tableData = tableData.filter((item) =>
        get_product(item?.product)?.toLowerCase().includes(search)
      );
    }

    if (date && date.start && date.end) {
      const startDate = new Date(date.start);
      const endDate = new Date(date.end);

      tableData = tableData.filter((item) => {
        const itemDate = parseDate(item.expiry_date);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    fetchData(tableData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <table className="w-full bg-white">
        <thead className="text-gray-500">
          <tr>
            <td className="border p-4">Name</td>
            <td className="border p-4">Batch</td>
            <td className="border p-4">Expiry</td>
            <td className="border p-4">Tax</td>
            <td className="border p-4">Stock</td>
            <td className="border p-4">Strips</td>
            <td className="border p-4">Tablets</td>
            <td className="border p-4">Purchase</td>
            <td className="border p-4">Sale</td>
          </tr>
        </thead>
        {/* <tbody>
          {reports?.map((value, index) => {
            return (
              <tr>
                <td className="border p-4">{value?.name}</td>
                <td className="border p-4">{value?.batch}</td>
                <td className="border p-4">{value?.expiry}</td>
                <td className="border p-4">{value?.tax}</td>
                <td className="border p-4">{value?.stock}</td>
                <td className="border p-4">{value?.strips}</td>
                <td className="border p-4">{value?.tablets}</td>
                <td className="border p-4">{value?.purchase}</td>
                <td className="border p-4">{value?.sale}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4"></td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.stock),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.strips),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.tablets),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.purchase),
                0
              )
              ?.toFixed(3)}
          </td>
          <td className="border p-4">
            {reports
              ?.reduce(
                (acc, currentValue) =>
                  parseFloat(acc) + parseFloat(currentValue?.sale),
                0
              )
              ?.toFixed(3)}
          </td>
        </tfoot> */}
      </table>
    </Header>
  );
};

export default InventoryReport;
