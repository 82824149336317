import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import TableImage from "../../../../Componets/Image";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Buttons/action";
import Table from "../../../../Componets/Table";
import { get_all_purchases } from "../../../../Controllers/Purchases/Orders";
import toast from "react-hot-toast";
import tables from "../../../../Data/Tables/Purchases/Orders/tables";
import headers from "../../../../Data/Tables/Purchases/Orders/headers";
import Loadercomponent from "../../../../Componets/Loader";
import moment from "moment";

const PurchasesOrderTable = () => {
  const [loader, setLoader] = useState(false);

  const [body, setBody] = useState();

  let tag = (stock, notify) => {
    let data = "Stocked";

    if (stock == 0) {
      data = "Out of Stock";
    } else if (stock <= notify) {
      data = "Low";
    }

    return data;
  };

  let color = (stock, notify) => {
    let data = "green";

    if (stock == 0) {
      data = "red";
    } else if (stock <= notify) {
      data = "orange";
    }

    return data;
  };

  const formdata = (data) => {
    fetchData(data);
  };

  const actiondata = (data) => {
    fetchData(data);
  };

  const fetchData = async (tabledata) => {
    try {
      setLoader(true);

      const data = {
        search: tabledata?.search,
        sort: tabledata?.sort,
        supplier: tabledata?.supplier,
        contractor: tabledata?.contractor,
        status: tabledata?.status,
        date: tabledata?.date,
        due_date: tabledata?.due_date,
      };

      const purchases = await get_all_purchases(data);
      if (purchases?.status) {
        let data = [];

        purchases?.data?.map((value, index) => {
          data?.push({
            path: `/app/purchases/orders/view/${value?._id}`,
            Number: "#PO-" + value?.number,
            Supplier: value?.supplier?.name,
            Date: moment(value?.date).format("MMMM Do YYYY"),
            Due: moment(value?.due_date).format("MMMM Do YYYY"),
            Amount: value?.total?.toFixed(3),
            Status: <Status status={value?.status} />,
            Action: (
              <ActionButton
                edit={`/app/purchases/orders/edit/${value?._id}`}
                id={value?._id}
                api="delete-purchase"
                actiondata={actiondata}
              />
            ),
          });
        });

        setBody(data);
        setLoader(false);
      } else {
        toast?.error(purchases?.message);
        setLoader(false);
      }
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers} formdata={formdata}>
      <Loadercomponent loader={loader}>
        <Table head={tables} body={body} />
      </Loadercomponent>
    </Header>
  );
};

export default PurchasesOrderTable;
