import React from "react";
import axios from "axios";
import APIURL from "../../../Main/apiurl";

const create_product_unit = async (unit) => {
  try {
    const data = {
      name: unit?.name,
      status: unit?.status,
      branch: unit?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/create-product-unit`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const update_product_unit = async (unit) => {
  try {
    const data = {
      id: unit?.id,
      name: unit?.name,
      status: unit?.status,
      branch: unit?.branch,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/update-product-unit`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_unit = async (unit) => {
  try {
    const data = {
      id: unit?.id,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-product-unit`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_product_units = async (unit) => {
  try {
    const data = {
      search: unit?.search,
      status: unit?.status,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-units`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_product_unit_log = async (unit) => {
  try {
    const data = {
      search: unit?.search,
      sort: unit?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-product-unit-log`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

const get_all_product_unit_logs = async (unit) => {
  try {
    const data = {
      search: unit?.search,
      sort: unit?.sort,
    };

    const token = localStorage.getItem("user");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${APIURL}/get-all-product-unit-logs`,
      data,
      config
    );
    return response?.data;
  } catch (error) {
    return error;
  }
};

export {
  create_product_unit,
  update_product_unit,
  get_product_unit,
  get_all_product_units,
  get_product_unit_log,
  get_all_product_unit_logs,
};
