import React, { useEffect, useState } from "react";
import Header from "../../../../Componets/Header";
import Table from "../../../../Componets/Table";
import { toast } from "react-toastify";
import tables from "../../../../Data/Tables/Settings/Users/tables";
import headers from "../../../../Data/Tables/Settings/Users/headers";
import users from "../../../../Data/users.json";
import roles from "../../../../Data/roles.json";
import Status from "../../../../Componets/Status";
import ActionButton from "../../../../Componets/Actionbutton";

const UsersTable = () => {
  let header = headers;
  let head = tables;

  const [body, setBody] = useState();

  const get_role = (id) => {
    let role = "";
    roles?.map((value, index) => {
      if (id == value?.id) {
        role = value?.name;
      }
    });

    return role;
  };

  const fetchData = async () => {
    try {
      let data = [];

      users?.map((value, index) => {
        data?.push({
          Name: value?.name,
          Role: get_role(value?.role),
          Phone: value?.phone,
          Email: value?.email,
          Status: <Status status={value?.status} />,
          Action: <ActionButton edit={`/`} delete="/" />,
        });
      });

      setBody(data);
    } catch (errors) {
      toast?.error(errors?.message);
    }
  };

  const formdata = async (data) => {
    console.log(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={header} formdata={formdata}>
      <Table head={head} body={body} />
    </Header>
  );
};

export default UsersTable;
