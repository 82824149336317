const tables = [
  {
    name: "Number",
  },
  {
    name: "Product",
  },
  {
    name: "Purchase",
  },
  {
    name: "Sale",
  },
  {
    name: "Tax",
  },
  {
    name: "Stock",
  },
  {
    name: "Expiry",
  },
  {
    name: "Status",
  },
  {
    name: "Action",
  },
];

export default tables;
