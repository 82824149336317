import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Forms from "../../../../Componets/Form";
import Header from "../../../../Componets/Header";
import forms from "../../../../Data/Forms/Settings/General/forms";
import headers from "../../../../Data/Forms/Settings/General/headers";
import toast from "react-hot-toast";
import { get_user_auth } from "../../../../Controllers/Layouts/Layouts";
import { update_user_auth } from "../../../../Controllers/Settings/General";
import Loadercomponent from "../../../../Componets/Loader";

const GeneralForm = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [values, setValues] = useState(forms);

  const formdata = async (data) => {
    if (data?.status) {
      setButtonLoader(true);

      const item = await update_user_auth(data?.data);

      if (item?.status) {
        if (id) {
          navigate(-1);
        }
        toast?.success(item?.message);
        setButtonLoader(false);
      } else {
        toast?.error(item?.message);
        setButtonLoader(false);
      }
    }
  };

  const handleData = async (item) => {
    let name = item?.name ? item?.name : "";
    let phone = item?.phone ? item?.phone : "";
    let area = item?.area ? item?.area : "";
    let city = item?.city ? item?.city : "";
    let state = item?.state ? item?.state : "";
    let country = item?.country ? item?.country : "";

    values[0].options[0].form.value = name;
    values[0].options[1].form.value = phone;

    values[1].options[0].form.value = area;
    values[1].options[1].form.value = city;
    values[1].options[2].form.value = state;
    values[1].options[3].form.value = country;

    setLoader(false);
    setValues([...values]);
  };

  const fetchData = async () => {
    setLoader(true);

    const item = await get_user_auth();
    if (item?.status) {
      handleData(item?.data);
    } else {
      toast?.error(item?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Header header={headers}>
      <Loadercomponent loader={loader}>
        <Forms
          form={values}
          setForm={setValues}
          formdata={formdata}
          buttonLoader={buttonLoader}
        />
      </Loadercomponent>
    </Header>
  );
};

export default GeneralForm;
