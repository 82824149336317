import { DeleteOutlined, EditOutlined, FormOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteSvg from "../../Assets/Svg/delete";
import { delete_table } from "../../Controllers/Global";
import toast from "react-hot-toast";

const ActionButton = (props) => {
  let navigate = useNavigate();

  const edit = props?.edit ? props?.edit : "";
  const id = props?.id ? props?.id : "";
  const api = props?.api ? props?.api : "";
  const actiondata = props?.actiondata;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = (e) => {
    e?.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  const handleDelete = async (e) => {
    e?.stopPropagation();

    const data = {
      api: api,
      id: id,
    };

    const deleteTable = await delete_table(data);
    if (deleteTable?.status) {
      actiondata(data?.id);
      toast?.success(deleteTable?.message);
      setIsModalOpen(!isModalOpen);
    } else {
      toast?.error(deleteTable?.message);
      setIsModalOpen(!isModalOpen);
    }
  };

  return (
    <div className="text-xl text-gray-600 ">
      <Modal
        open={isModalOpen}
        onCancel={handleModal}
        centered={true}
        footer={[
          <div>
            <div className="">
              <Button
                className="cancel-btn transition duration-700 ease-in-out font-medium mr-2"
                onClick={handleModal}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                className="delete-btn transition duration-700 ease-in-out font-medium text-red-500 border-red-500"
              >
                Delete
              </Button>
            </div>
          </div>,
        ]}
      >
        <div>
          <div className="flex justify-center item-center py-2">
            <DeleteSvg className="text-red-600" width={40} height={40} />
          </div>

          <div className="py-4">
            <div className="text-xl text-medium text-center">
              Your about to delete
            </div>
            <div className="py-4">
              <div className="text-base text-gray-400 text-center">
                Are you sure want to delete ? You
              </div>
              <div className="text-base text-gray-400 text-center">
                will <span className="text-red-500">not be able to revert</span>
                &nbsp;this.
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {edit && (
        <button
          className="mx-2 hover:text-[#2d53da]"
          onClick={(e) => {
            e.stopPropagation();
            navigate(edit); // Replace '/edit' with the actual path you want to navigate to
          }}
        >
          <FormOutlined />
        </button>
      )}
      {api && (
        <button className="mx-2 hover:text-red-500">
          <DeleteOutlined onClick={handleModal} />
        </button>
      )}
    </div>
  );
};

export default ActionButton;
